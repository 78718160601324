import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import TopHeroComponent from "../../components/herocomponents/topherocomponent.js"
import ContactForm from "../../components/bodycomponents/contact-form.js"
import RED_BG from "../../images/background-images/bg-red.svg"
import { colors } from "../../utils/colors.js"
import NewArrivalsComponent from "../../components/bodycomponents/newarrivalscomponent.js"
import NEW_ARRIVALL_BG from "../../images/buy-equipment/SSLLC-Equipment_New-Arrivals_Office-Equipment.png"

const FacilitiesEquipment = () => {
  return (
    <Layout>
      <SEO
        title={`Facilities & Office Equipment | Surplus Solutions Inventory`}
        description={`Browse our selection of facilities and office equipment at Surplus Solutions. Find the tools you need for your business operations.`}
      />
      <TopHeroComponent
        backgroundImage={RED_BG}
        backgroundColor={colors.scarletRed}
        fontShadowColor={colors.red}
        shadowColor="shadow-red-bottom"
        pageTitle="Facilities & Office Equipment"
        title="Premium New and Used Facilities & Office Equipment"
        body={
          <p className="pt-4">
            Surplus Solutions offers premium new and used facilities and office
            equipment to meet your operational needs. From IT equipment and
            phone booths to ergonomic chairs and conference tables, our
            inventory provides reliable solutions for any workspace. Explore our
            collection or contact us for custom solutions.
          </p>
        }
      />
      {/* <div className="shadow-custom-out">
        <WrappingList
          title="Shop All Facilities & Office Equipment"
          data={level2Items}
          slugPrefix={"/catalog"}
          level3Mapping={level3Mapping}
        />
      </div> */}
      <NewArrivalsComponent
        backgroundImage={NEW_ARRIVALL_BG}
        title={
          <>
            New Arrivals
            {/* <br />
            Facilities Gear */}
          </>
        }
        body={
          <p>
            Our inventory is frequently updated and tends to sell out quickly.
            Discover the latest lab, processing, and packaging equipment
            arrivals and find the best deals before they run out. The best
            in-demand items often find their buyers almost as fast as they
            appear in our inventory. Be sure to act now and secure the equipment
            you need at excellent prices!
          </p>
        }
        buttonTitle="See New Arrivals >"
        buttonColor={colors.turquoise}
        hoverColor={colors.aqua}
      />
      <ContactForm
        title="Never miss a great deal!"
        body={
          <p>
            Receive weekly emails with our special offers, new arrivals,
            auctions, case studies and more!
          </p>
        }
        fontShadowColor={colors.red}
        backgroundColor={colors.containerGray}
        shadow="shadow-red-bottom"
        formType="Marketing Sign Up"
      />
    </Layout>
  )
}

export default FacilitiesEquipment
