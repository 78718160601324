import React from "react"
import { Link } from "gatsby"
import newArrivalIcon from "../../images/buy-equipment/SSLLC Icon_New Arrivals_No Padding.svg"

export default function NewArrivalsComponent(props) {
  return (
    <>
      <div className="shadow-custom-in">
        <div className="container max-w-screen-xl w-full py-14">
          <div className="w-full flex flex-col md:flex-row items-center space-x-1 ">
            {" "}
            <div className="w-full md:w-1/2 lg:w-2/5 py-5 px-7">
              <div className="flex items-center justify-start">
                <span className="mr-4">
                  <img
                    src={newArrivalIcon}
                    alt={`A cartoon image of a teal badge reading "NEW"`}
                    className="w-24"
                  />
                </span>
                <h3 className="">{props.title}</h3>
              </div>
              <span>{props.body}</span>
            </div>
            <div
              className="w-full md:w-1/2 lg:w-3/5 flex items-center bg-contain bg-no-repeat bg-right h-[22rem]"
              style={{
                backgroundImage: `url(${props.backgroundImage})`,
              }}
            >
              <div className="flex pl-10 w-full h-full items-center">
                <Link
                  to="/catalog/new-arrivals"
                  type="submit"
                  className="text-charcoal hover:text-charcoal px-5 py-2 text-[1.2rem] rounded-[4px] transition duration-200"
                  style={{
                    backgroundColor: props.buttonColor, // Regular background color
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = props.hoverColor)
                  } // Hover background
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = props.buttonColor)
                  } // Reset background
                >
                  {props.buttonTitle}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
